import React from "react"
import ArticleTemplate from "../../templates/articleTemplate"
import PageTemplate from "../../templates/pageTemplate"

const Preview = props => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    window.addEventListener("message", event => {
      // IMPORTANT: check the origin of the data!
      if (event.origin.startsWith(process.env.GATSBY_API_URL)) {
        console.log(event.data)
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        setData(event.data)
      } else {
        // The data was NOT sent from your site!
        // Be careful! Do not use it. This else branch is
        // here just for clarity, you usually shouldn't need it.
        return
      }
    })
  })

  switch (props.params.type) {
    case "page":
    case "pages":
      return <PageTemplate {...props} data={{ collectionArticles: data }} />

    case "article":
    case "articles":
      return <ArticleTemplate {...props} data={{ collectionArticles: data }} />

    default:
      return null
  }
}

export default Preview
