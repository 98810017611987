import { graphql } from "gatsby"
import React from "react"
import { Box, Heading } from "grommet"
import { Layout } from "@bw/layouts"
import {
  Seo,
  Section,
  Container,
  Button,
  ArticleButton,
  Image,
  Avatar,
  KeyValue,
} from "@bw/bits"
import { Share } from "@bw/partials"
import * as modules from "@bw/modules/resolvers"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const StyledBox = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin: 100px 0px;
`

const StyledShare = styled(Box)``

const StyledArtists = styled(Box)`
  a {
    font-size: 14px;
    line-height: 23px;
    color: #04062c;
    opacity: 0.7;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.5;
    }
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const ArticleTemplate = ({ data, location, pageContext }) => {
  const { next, previous } = pageContext
  const { t } = useTranslation()

  const page = data.collectionArticles

  const date = new Date(page.date).toLocaleDateString(page.locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      <Section containerSize="large">
        <Button
          to="/blog"
          label={t("return")}
          style={{ marginBottom: "30px" }}
        />
        <Container size="small">
          <Image
            type="BlogDetail"
            image={page?.teaserImage?.localImage}
            tag={date}
            avatar={<Avatar />}
          />
        </Container>
      </Section>
      <Section title={page.title} containerSize="small">
        <KeyValue
          indicator={t("article.author")}
          variable={page.authors.map(a => a.name).join(", ")}
          fontSize="14px"
        />
      </Section>

      {page.content
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          return <LoadedModule key={i} {...module} containerSize="small" />
        })}
      <Section containerSize="large">
        <Container size="small">
          <Flex>
            <StyledArtists>
              <Heading level="5">{t("article.artists")}</Heading>
              <div style={{ display: "flex" }}>
                <a href="http://localhost:8000/fr/user/[address]/">ANdy</a>
                <a href="http://localhost:8000/fr/user/[address]/">Josh</a>
                <a href="http://localhost:8000/fr/user/[address]/">ELArtist</a>
              </div>
            </StyledArtists>
            <StyledShare>
              <Heading level="5">{t("article.share")}</Heading>
              <Share
                fillColor="#6080E9"
                row={true}
                quote={page.title}
                pageURL={location.href}
              />
            </StyledShare>
          </Flex>
        </Container>
        <Container size="large">
          <StyledBox>
            <div>
              {previous && (
                <ArticleButton label={t("article.prev")} to={previous.slug} />
              )}
            </div>
            <div>
              {next && (
                <ArticleButton
                  label={t("article.next")}
                  to={next.slug}
                  type="next"
                />
              )}
            </div>
          </StyledBox>
        </Container>
      </Section>
    </Layout>
  )
}

export default ArticleTemplate

export const articleQuery = graphql`
  query articleQuery($id: String!) {
    collectionArticles(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      date
      locale
      image {
        url
      }
      doNotIndex
      teaserImage {
        localImage {
          childImageSharp {
            gatsbyImageData(width: 555, height: 375)
          }
        }
      }
      authors {
        name
      }
      header {
        __typename
        suptitle
        title
        subtitle

        ... on HeroModule {
          ...HeroModuleFragment
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        centerTitle
        backgroundVariant

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ColumnsModule {
          ...ColumnsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
      }
    }
  }
`
